.light{
  --text-color: var(--black);
  --bg-color: var(--white);
}

.neutral{
  --text-color: var(--black);
  --bg-color: var(--beige);
}

.dark{
  --text-color: var(--white);
  --bg-color: var(--sauge);
}

.transparent {
  --text-color: var(--sauge);
  --bg-color: transparent;
}

.wrapper{
  background: var(--bg-color);
}

.container {
  --padding-container: var(--spacing-100) var(--spacing-80);
  display: flex;
  gap: var(--spacing-8);
  background: var(--bg-color);
  color: var(--text-color);
  padding: var(--padding-container);
  text-align: center;
}

@media (max-width: 1024px) {
  .container{
    --padding-container: var(--spacing-40) var(--spacing-24);
  }
}

@media (max-width: 768px) {
  .container{
    display: grid;
    --padding-container: var(--spacing-40) var(--spacing-24);
    grid-template-columns: repeat(1, minmax(auto,1fr)) !important;
  }
}